/* global webkitRequestAnimationFrame msRequestAnimationFrame safari eb_raf */

var ebates = ebates || {};

window.eb_raf = requestAnimationFrame || webkitRequestAnimationFrame || msRequestAnimationFrame || function(f){
    return setTimeout(f, 0);
};

ebates.generateGuid  = function () {
    var ts = new Date().getTime();
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
        s4() + '-' + ts;
};

// --- UI helpers --- //
ebates.UI = ebates.UI || {};
ebates.UI.isInView = function (el, options) {   // single options argument is forward compatible to future extension
    var isOptionsObject = typeof options === 'object',
        defaultVisibilityFraction = 0.5,
        opts = isOptionsObject ? {
            fraction: options.fraction || defaultVisibilityFraction,
            threshold: {
                x: options.threshold && options.threshold.x || 0,
                y: options.threshold && options.threshold.y || 0
            },
            checkVisibility: typeof options.checkVisibility !== 'undefined' ? options.checkVisibility : true
        } : {
            fraction: options || defaultVisibilityFraction,
            threshold: {
                x: 0,
                y: 0
            },
            checkVisibility: true
        },
        boundingRect,
        metrics;
    if (opts.checkVisibility && document.hidden || !el) {
        return false;
    } else {
        boundingRect = el.getBoundingClientRect();
        metrics = {
            x: {
                right: Math.max(Math.min(-(window.innerWidth - boundingRect.right) - opts.threshold.x, boundingRect.width), 0),
                left: Math.max(Math.min(-boundingRect.left - opts.threshold.x, boundingRect.width), 0),
                visible: {
                    get amount(){
                        return boundingRect.width - metrics.x.left - metrics.x.right;
                    },
                    get fraction() {
                        return metrics.x.visible.amount / (metrics.x.visible.amount + metrics.x.left + metrics.x.right);
                    }
                }
            },
            y: {
                top: Math.max(Math.min(-boundingRect.top - opts.threshold.y, boundingRect.height), 0),
                bottom: Math.max(Math.min(-(window.innerHeight - boundingRect.bottom) - opts.threshold.y, boundingRect.height), 0),
                visible: {
                    get amount(){
                        return boundingRect.height - metrics.y.top - metrics.y.bottom;
                    },
                    get fraction() {
                        return metrics.y.visible.amount / (metrics.y.visible.amount + metrics.y.top + metrics.y.bottom);
                    }
                }
            }
        };
        el.latestInViewMetrics = metrics;
        return metrics.y.visible.fraction * metrics.x.visible.fraction >= opts.fraction;
    }
};

/**
 *  Scroll library; throttled by default
 *  TODO - This is battle tested, still, unit testing would not hurt
 */
ebates.UI.scroll = {
    events: [],
    /**
     * @param action - scroll handler
     * @param container - scrolling container the handler will be attached to; optional, defaults are provided
     * @param throttle - the rate of it; optional; set it to higher/lower value as needed
     * @returns {number} - scroll listener id
     */
    add: (action, container = document.getElementById('scrolling-container') || document, throttle = 50) => {
        let actionPending = false;

        const act = () => {
                action();
                actionPending = false;
            },
            handler = () => {
                if (!actionPending) {
                    setTimeout(act, throttle);
                    actionPending = true;
                }
            };

        container.addEventListener('scroll', handler);
        return ebates.UI.scroll.events.push({ container, handler, off: false, throttle }) - 1;
    },
    /**
     * turns scroll listener off
     * @param eventListenerId - event listener id
     */
    off: eventListenerId => {
        const event = ebates.UI.scroll.events[eventListenerId];

        event && event.container.removeEventListener('scroll', event.handler);
        event.off = true;
    },
    /**
     * reactivates the scroll listener
     * @param eventListenerId
     */
    reactivate: eventListenerId => {
        const event = ebates.UI.scroll.events[eventListenerId];

        event && event.off && event.container.addEventListener('scroll', event.handler);
    }
};

// --- RegEx's --- //
ebates.regex = {
    email: /^([-\w+~]+(?:\.[-\w+~]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i,
    IE: /(msie|trident|edge[ /])/i,
    specialId: /special=(\d+)/
};

// --- Cookies --- //
ebates.cookie = {
    set: function (name, value, days) {
        var d = new Date(); d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days); document.cookie = name + '=' + value + ';expires=' + d.toUTCString() + ';path=/;';
    },
    get: function (name) {
        var c = document.cookie.split(name + '='); return c.length === 2 ? c.pop().split(';').shift() : false;
    }
};

// --- Prameters -  --- //
ebates.param = {
    get: function (name, url) {
        var param = '&' + name + '=',
            params = url && (url.indexOf('?') !== -1) && ('&' + url.split('?')[1].split('#')[0])
                || typeof url === 'undefined' && window.location.search.replace('?', '&') || '';
        return params.indexOf(param) !== -1 && decodeURIComponent(params.split(param)[1].split('&')[0]);
    },
    add: function (name, val, url) {
        var urlArr = (typeof url !== 'undefined' ? `${url}` : window.location.href).split('#'),
            updatedUrl = urlArr[0],
            hash = urlArr.length > 1 && '#' + urlArr[1] || '',
            currentVal = ebates.param.get(name, updatedUrl);
        if (currentVal !== val) {
            currentVal !== false && (updatedUrl = ebates.param.remove(name, updatedUrl));
            updatedUrl += (updatedUrl.indexOf('?') === -1 && '?' || '&') + name + '=' + encodeURIComponent(val) + hash;
            typeof url === 'undefined' && history.pushState({}, '', updatedUrl);
        }
        return updatedUrl;
    },
    remove: function (name, url) {
        var updatedUrl = typeof url !== 'undefined' ? `${url}` : window.location.href,
            rgxParam = new RegExp('([?&])' + name + '=[^&#]*');
        updatedUrl = updatedUrl.replace(rgxParam, '$1').replace(/([?&])&/, '$1').replace(/[?&]($|#.*)/, '$1');
        typeof url === 'undefined' && history.pushState({}, '', updatedUrl);
        return updatedUrl;
    }
};

// --- Ebates Require - dependency manager / script loader, a light-weight and efficient require.js replacement -- //
ebates.js = {
    registry: [],   // global static script registry
    require: function() {
        var queue = {},
            params = {dataType: 'script', cache: true, crossDomain: true},
            args = [].slice.call(arguments),
            action = args.length && typeof args[args.length - 1] === 'function' && args.pop(),
            registry = this.registry;
        $.each(args, function(i, s){
            !registry[s] && (queue[s] = registry[s] = $.ajax(s, params)) || registry[s].status !== 200 && !queue[s] && (queue[s] = registry[s]);
        });
        $.when.apply($, $.map(queue, function(o){
            return o;
        })).then(action);
        return this;
    }
};

// --- Ebates CSS loader -- //
ebates.css = {
    registry: {},   // global static CSS file registry
    require: function() {
        var queue = {},
            hasCallback = typeof arguments[arguments.length - 1] === 'function',
            args, action, i,
            actionDone = null,
            registry = this.registry,
            injectTag = function(file){
                var link = '<link rel="stylesheet" href="' + file + '">',
                    el = document.createElement('div');
                eb_raf(function(){
                    setTimeout(function(){
                        document.body.appendChild(el); el.outerHTML = link;
                    }, 0);
                });
            };
        if (hasCallback) {
            args = [].slice.call(arguments);
            action = args.pop();
            $.each(args, function(i, file){    // add to registry only new and not-pending CSS file requests
                var params = {url: file, cache: true, crossDomain: true, complete: injectTag.bind(null, file), timeout: 1000};
                if (!registry[file]) {  // not in registry - load the file
                    registry[file] = $.ajax(params);
                    queue[file] = registry[file];
                } else if (registry[file].readyState !== 4 && !queue[file] ) { // in registry, pending but not in queue - add the promise to queue
                    queue[file] = registry[file];
                }
            });
            $.when.apply($, $.map(queue, function(o){
                return o;
            })).
                then(function(){
                    actionDone = setTimeout(action, 0);
                }).
                always(function(){
                    actionDone === null && setTimeout(action, 0);
                });
        } else {
            for (i = 0; i < arguments.length; i++) {
                if (!registry[arguments[i]]) {
                    injectTag(arguments[i]);
                    registry[arguments[i]] = {status: 200};
                }
            }
        }
        return this;
    }
};

/* --- Generic, constant time .get/.set, fixed size, circular buffer/cache; if size limit is reached the oldest item is overwritten first --- */
ebates.cache = function(param) { // param - either serialized object or int size
    this.c = typeof param === 'object' && param || {p: 0, cache: {}, tss: {}, keys: [], sz: param && Math.abs(param) || 10};
};
ebates.cache.prototype.get = function( key ) {
    return this.c.cache[key];
};
ebates.cache.prototype.set = function(val, key) {   // to preserve the "constant time" promise we can't loop over the cache in this function!
    var i = this.c.p,   // curent index/iterator
        k = key || i;   // if key is not provided use internal index
    if (typeof this.c.cache[k] !== 'undefined') {   // if existing key - just update the value
        this.c.cache[k] = val;
        this.c.tss[k] = Date.now();
    } else {    // if new key - add new item and delete the overflowing oldest item
        if (typeof this.c.keys[i] !== 'undefined' && typeof this.c.cache[this.c.keys[i]] !== 'undefined') {
            delete this.c.cache[this.c.keys[i]];
            delete this.c.tss[this.c.keys[i]];
        }
        this.c.keys[i] = k;
        this.c.cache[k] = val;
        this.c.tss[k] = Date.now();
        this.c.p = (this.c.p + 1) % this.c.sz;
    }
    return this;
};
ebates.cache.prototype.getTs = function( key ){
    return this.c.tss[key];
};
// ebates.cache.serialize - for localStorage caching
ebates.cache.prototype.serialize = function( stringify ){
    this.c.ts = Date.now(); return stringify ? JSON.stringify(this.c) : this.c ;
};

/*
    //Source https://stackoverflow.com/questions/5916900/how-can-you-detect-the-version-of-a-browser/38080051#38080051
    Browser detection including type and version.
    @Returns {object} An object includes name and version of Browser, eg: { name: "Firefox", version: "42" }
 */
ebates.getBrowser = function (user_agent) {
    var ua, tem, M;
    if (user_agent){
        ua = user_agent;
    } else {
        ua = navigator.userAgent;
    }
    M = ua.match(/(opera|chrome|safari|firefox|msie|trident|FxiOS|CriOS(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])){
        tem =  /\brv[ :]+(\d+)/g.exec(ua) || [];
        return {name: 'IE', version: (tem[1] || '')};
    }
    if (M[1] === 'Chrome'){
        tem = ua.match(/\b(OPR|Edge?)\/(\d+)/);
        if (tem != null) return {name: tem[1].replace('OPR', 'Opera').replace('Edg$', 'Edge'), version: tem[2]};
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    if (M[0] === 'FxiOS'){
        M[0] = 'Firefox';
    }
    if (M[0] === 'CriOS'){
        M[0] = 'Chrome';
    }
    if (M[0] === 'Edg'){
        M[0] = 'Edge';
    }

    return {name: M[0], version: M[1]};
};

/*
  Detect if the localstorage is read/writable. The result is not guarantee that the data in localstorage will be persistent once browser close.
  @returns {boolean} true if the localstorage is read and writable. Otherwise false.
 */
ebates.isLocalstorageAvailable = function() {
    var testVar;
    if (typeof ebates.localStorage !== 'undefined') {
        return ebates.localStorage;
    } else {
        testVar = 'localstorge' + Date.now();
        try {
            localStorage.setItem(testVar, 'test');
            localStorage.removeItem(testVar);
            ebates.localStorage = true;
            return true;
        } catch (e) {
            ebates.localStorage =  false;
            return false;
        }
    }
};

/* --- Emit throttled scroll event - to avoid scrolling performance issues (sticky header and other sticky modules) --- */
$(function(){
    var threshold = 50,
        t = null,
        last = Date.now && Date.now() || new Date().getTime();
    $(window).on('load resize scroll', function(){
        var now = Date.now && Date.now() || new Date().getTime();   // expire "|| new Date().getTime()" with IE8 support
        if (now > threshold + last){
            last = now; $(window).trigger('scroll-throttle');
            clearTimeout(t);
            t = setTimeout(function(){
                $(window).trigger('scroll-throttle');
            }, threshold);
        }
    });
});

// --- End of generic (non-DOM-element specific)  ebates library --- //

/* --- Browser detection, for Button --- */
(function(){
    var ua = navigator.userAgent;
    ebates.button = {
        browser: /edge?\//i.test(ua) && 'edge' || !ebates.regex.IE.test(ua) &&
        ( /avast\//i.test(ua) && 'avast' || /firefox\//i.test(ua) && 'firefox' || /AppleWebKit\//i.test(ua) &&
            (!!window.chrome && 'chrome' ||
            ((Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0 ||
                (function (p) {
                    return p.toString() === '[object SafariRemoteNotification]';
                })(!window['safari'] || safari.pushNotification)) && 'safari'
            ) || 'unknown')
        ) || 'unknown',
        isButtonInstalled : function () {
            return !!(document.querySelector('html') && document.querySelector('html').getAttribute('extension-installed') || ebates.cookie && ebates.cookie.get('toolbarId') || document.querySelector('ebatestoolbar'));
        }
    };
})();

// Handle Apple sign in close window and reload page
window.addEventListener('DOMContentLoaded', function () {
    if (ebates.param.get('social_media') === 'APL' && window.opener && window.opener.location.host === location.host){
        window.name = 'Rakuten-AppleSignInWindow';
        const tempWindow = window.opener.open('/', window.name, 'height=500,width=600,left=200,top=100,resizable=yes,scrollbars=yes');
        let tempUrl = ebates.param.add('type', ebates.param.get('type'), window.opener.location.href);

        tempUrl = ebates.param.add('social_media', ebates.param.get('social_media'), tempUrl);
        window.opener.location.assign(tempUrl);
        tempWindow.close();
    }
});

